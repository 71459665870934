import React from 'react';

import Image from 'next/image';
import { useRouter } from 'next/router';

import { PROJECTS_PATHS } from '@constants/index'
import { imagesParse } from '@utils/projects/imagesParse';
import ChainIcon from '@widget/projects/ChainIcon';

import styles from './index.module.less';
const defaultObj = {};
const keyWordsCount = 4

const RecommendItem = (props) => {
  const { recommendItem = defaultObj } = props;
  const { images, name, chainInfo, keyWords, customerId } = recommendItem;
  const parseImages = imagesParse(images);
  const logoPath = parseImages?.logo?.[0];
  const imagePath = logoPath || require('@images/projects/project1.webp');
  const keyWordsStr = (recommendItem?.keyWords?.slice?.(0, keyWordsCount))
    .map(keyItem => (`#${keyItem} `));
  const router = useRouter();
  const toDetail = (e) => {
    e.stopPropagation()
    router.push({
      pathname: `${PROJECTS_PATHS.PROJECT}/${customerId}`
    })
  }

  return (
    <div className={styles.listItem} onClick={toDetail}>
      <div className={styles.brief}>
        <div className={styles.projectImage}>
          <Image
            src={imagePath}
            width={96}
            height={96}
            layout='responsive'
            alt="kikitrade"
          />
        </div>
        <div className={styles.projectTitle}>
          <div className={styles.projectName}>
            {name}
          </div>
          <ChainIcon chainInfo={chainInfo} isStack />
        </div>
        {keyWords && <div className={styles.keyWords}>
          {keyWordsStr}
        </div>}
      </div>
    </div>
  );
};

export default RecommendItem;
