import React from 'react';
import styles from './index.module.css'
import I18n from '@utils/i18n';
import Image from 'next/image'
import cn from 'classnames'
import { useClient, useAuthSession } from '@lib/hooks'
import StartUse from '@components/StartUse'
import { Button } from '@element/Button'
import Icon from '@components/Icon'
import Router from 'next/router';
import zh from '@images/logo_zh.webp'
import hk from '@images/logo_hk.webp'
import en from '@images/logo_en.webp'
import helper from '@utils/helper'

const ImgMap = {
  zh,
  hk,
  en
}

const registerButtom = (isLogin) => {
  if (!isLogin) {
    return <div className={styles.quick} onClick={() => onClick('/register')} data-buried="TRkOl8IA">
            <span>{I18n.t('TRkOl8IA')}</span>
            <Icon name='arrowRight'/>
         </div>
  }
  return ''
}

const onClick = (path) => {
  Router.push(path)
}

const QuickStart = () => {
  const isPc = useClient()

  const isLogin = useAuthSession({ redirectTo: null });
  if (isPc === '') {
    return <div className={styles.emptyStyle}></div>
  }
  const titleDom = <h1 className={cn(styles.line1, 'Medium')}>{I18n.t('uuNCpwPP')}</h1>
  return (
    <div className={styles.pageTop} key='web'>
      <div className={styles.contain}>
          <div className={styles.left}>
            {registerButtom(isLogin)}
          {titleDom}
          <p className={styles.line2}>{I18n.t('vqu8j53i')}</p>
            {
              isLogin
                ? (
                <Button type='primary' className={styles.buttom} onClick={() => onClick('/market')}>{I18n.t('oVxBnBTh')}</Button>
                  )
                : (
                <StartUse />
                  )
            }
          </div>
          <div className={styles.right}>
                    <Image
                      src={ImgMap[helper.getLocaleKey()]}
                      layout="fixed"
                      width={588}
                      height={498}
                      alt=''
                  />
          </div>
        </div>
    </div>
  );
}

export default QuickStart
