import React from 'react'
import { NewPersonList } from './NewPersonList'
import cn from 'classnames';
import I18n from '@utils/i18n';
import styles from './index.module.css'

const NewGuide = () => {
  return (
      <div>
        <h2 className={cn(styles.NewGuideTitle, 'Medium')}>{I18n.t('newbie_guide')}</h2>
        {/* <div className={styles.NewTaskTag}>{I18n.t('new_task')}</div> */}
        <div className={styles.bannerData}>
          <NewPersonList />
        </div>
      </div>
  )
}
export default NewGuide
