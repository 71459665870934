import React from 'react';

import cn from 'classnames'
// import I18n from '@utils/i18n';
import Image from 'next/image'
import Link from 'next/link'

import { useClient } from '@lib/hooks'
import { I18n } from '@utils/index';

import styles from './index.module.css'

const DownloadArea = () => {
  const isPc = useClient()

  return (
    <div className={styles.pageTop} key='web'>
      <div className={styles.contain}>
        <div className={styles.intro}>
          <Image
            src={require('@images/download_intro.webp')}
            layout="responsive"
            width={isPc ? 607 : 327.5}
            height={isPc ? 532 : 287}
            alt=''
          />
        </div>

        <div className={styles.download}>
          <div>
            <div className={styles.line1}>
              {I18n.t('AoaqSsmJ')}
            </div>
            <div className={styles.line2}>{I18n.t('2mdPkm1l')}</div>
          </div>

          <div className={styles.downloadInfo}>
            <div className={styles.btnWrapper}>
              <Link legacyBehavior href="https://app.adjust.com/h7et2hs">
                <a target="_blank">
                  <div className={cn(styles.btn, styles.btn1)} />
                </a>
              </Link>

              <Link legacyBehavior href="https://app.adjust.com/n2d212k">
                <a target="_blank">
                  <div className={cn(styles.btn, styles.btn2)} />
                </a>
              </Link>
            </div>
            <div className={styles.scanImg}>
              <Image
                src={require('@images/login/qrcode.webp')}
                layout="responsive"
                width={118}
                height={118}
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadArea
