import React, { useState, Fragment } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import helper from '@utils/helper';
import styles from './index.module.css'
import { useIsomorphicLayoutEffect } from '@lib/hooks'
import { getRemoteConfigValue } from '@utils/firebase'
import cn from 'classnames';
export const NewPersonList = () => {
  const [newGuiDe, setNewGuiDe] = useState([])
  useIsomorphicLayoutEffect(() => {
    getRemoteConfigValue(`new_guide_web_${helper.getLocaleKey()}`).then((res) => {
      try {
        setNewGuiDe(JSON.parse(res.asString()))
      } catch (e) {
      }
    })
  }, [])

  return (<Fragment>
    {
      newGuiDe.map(item => (
        <div className={styles.bannerCont} key={item.id} data-buried="newbie_guide">
            <Link href={item.url}>
                <a target={'_blank'}>
                    <div className={styles.bannerImage}>
                        <Image
                            src={item.image}
                            width={388}
                            height={206}
                            alt={'kikitrade'}
                        />
                        <div className={cn(styles.titleDataCont, 'DINPro')}>{item.title}</div>
                    </div>
                </a>
            </Link>
            {/* <div className={styles.bannerDesc}>{item.text}</div> */}
        </div>
      ))
    }
    </Fragment>
  )
}
